<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="dp_brand" ref="dp_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img src="/image/engage-metriq-logo.png?v=1.0.1" alt="Engage Metriq Logo" />
        <img src="/image/side-logo.jpg?v=1.0.1" alt="Engage Metriq Logo" class="side-logo" />
      </router-link>
    </div>
    <!-- <div class="brand-tools ml-2"  >
      <button class="brand-toggle" id="dp_aside_toggle" ref="dp_aside_toggle">
        <i
          class="fas fa-chevron-left sidebar-arrow text-dark-50 text-hover-dark"
          id="dp_aside_toggle_icon"
        ></i>
      </button>
    </div> -->
  </div>
  <!-- end:: Aside -->
</template>

<script>
export default {
  name: 'DPBrand', 
};
</script>
